import React from 'react';
import {
  Box,
  Button,
  Dialog,
  DialogContent,
  DialogTitle,
  Fab,
  Grid,
  InputAdornment,
  TextField,
  Typography,
} from '@mui/material';
import { WithStyles } from '@mui/styles';
import withStyles from '@mui/styles/withStyles';
import { buildQaId } from 'utils/build-qa-id';
import { colors } from 'lib/themes';
import classNames from 'classnames';
import { QrCodeScanner } from '@mui/icons-material';
import { windowFeatureIsEnabled } from 'config/window-features';
import { usePrevious } from 'hooks/usePrevious';
import { useTimeoutFn } from 'hooks/useTimeoutFn';
import useScanDetection from 'use-scan-detection';
import { IShippingVerification, PackageLabelScanStatus, ShippingLabelScanStatus } from './types';
import { ShippingStyles } from './shipping.styles';
import { fakeScan } from './utils';
import { SCAN_DETECTION_SETTINGS } from './use-shipping-scan';
import { ancillarySupplies } from '../../constants/lists';

interface IShippingProps extends IShippingVerification, WithStyles<typeof ShippingStyles> {}

const statusBoxStyle = {
  fontSize: '14px',
  lineHeight: '17px',
  padding: '8px 20px',
  textTransform: 'uppercase',
  color: 'white',
  display: 'inline-block',
  borderRadius: '30px',
  fontWeight: 400,
  letterSpacing: '-0.3px',
};

const PackageLabelScanStatusText: Record<PackageLabelScanStatus, string> = {
  [PackageLabelScanStatus.Pending]: 'Package Label Scan Pending',
  [PackageLabelScanStatus.Complete]: 'Package Label Scan Complete',
};
const ShippingLabelScanStatusText: Record<ShippingLabelScanStatus, string> = {
  [ShippingLabelScanStatus.Pending]: 'Shipping Label Scan Pending',
  [ShippingLabelScanStatus.Complete]: 'Shipping Label Scan Complete',
  [ShippingLabelScanStatus.Incorrect]: 'Incorrect Label',
};
const PackageLabelScanStatusColor: Record<PackageLabelScanStatus, string> = {
  [PackageLabelScanStatus.Pending]: colors.yellow,
  [PackageLabelScanStatus.Complete]: colors.teal,
};
const ShippingLabelScanStatusColor: Record<ShippingLabelScanStatus, string> = {
  [ShippingLabelScanStatus.Pending]: colors.yellow,
  [ShippingLabelScanStatus.Complete]: colors.teal,
  [ShippingLabelScanStatus.Incorrect]: colors.lightRed,
};

export function PackingVerificationStatus({ status }: { status: PackageLabelScanStatus }) {
  return (
    <Box sx={{ ...statusBoxStyle, background: PackageLabelScanStatusColor[status] }}>
      {PackageLabelScanStatusText[status]}
    </Box>
  );
}
export function ShippingVerificationStatus({ status }: { status: ShippingLabelScanStatus }) {
  return (
    <Box sx={{ ...statusBoxStyle, background: ShippingLabelScanStatusColor[status] }}>
      {ShippingLabelScanStatusText[status]}
    </Box>
  );
}
export function DebugScanButton() {
  const isEnabled = windowFeatureIsEnabled('shipping_scanner_simulator');
  const [debugScannerInput, setDebugScannerInput] = React.useState('');
  const handleClick = React.useCallback(() => {
    fakeScan(debugScannerInput);
    setDebugScannerInput('');
  }, [setDebugScannerInput, debugScannerInput]);

  // Prevent issues from users scanning from a real device into the simulator input.
  useScanDetection({
    ...SCAN_DETECTION_SETTINGS,
    onComplete: (_scannedLabelCode: unknown) => {
      if (!isEnabled) return;
      setDebugScannerInput('');
    },
  });

  return isEnabled ? (
    <Box
      sx={theme => ({
        position: 'fixed',
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'flex-start',
        top: theme.spacing(10),
        right: theme.spacing(2),
        padding: '8px 10px',
        borderRadius: '120px',
        background: theme.palette.grey[200],
      })}
    >
      <Fab
        onClick={handleClick}
        size="small"
        sx={theme => ({ background: theme.palette.grey[100] })}
      >
        <QrCodeScanner />
      </Fab>
      <TextField
        variant="outlined"
        placeholder='ex: "872634-FC"'
        autoComplete="off"
        size="small"
        sx={{
          'marginLeft': '8px',
          'background': 'white',
          'borderRadius': '120px',
          '& fieldset': { display: 'none' },
        }}
        onChange={evt => {
          const nextValue = evt.target.value.trim();
          setDebugScannerInput(val => (nextValue !== val ? nextValue : val));
        }}
        onKeyUp={evt => {
          if (evt.key === 'Enter') {
            evt.preventDefault();
            handleClick();
          }
        }}
        value={debugScannerInput}
      />
    </Box>
  ) : null;
}

const valueWithSLSuffix = (value: string) => {
  return !value.endsWith('-SL') && value.match(/^[0-9]+$/g) ? `${value}-SL` : value;
};

export const ShippingLabelScan = withStyles(ShippingStyles)(ShippingLabelScanBase);
export function ShippingLabelScanBase({
  scannedShippingLabel,
  packageScanStatus,
  shippingScanStatus,
  processScanInput,
  classes,
  isShippingLabelPrinted,
  orderId,
  isLoadingScanHistory,
}: IShippingProps & { isShippingLabelPrinted?: boolean }): JSX.Element {
  const [inputValue, setInputValue] = React.useState('');
  const inputRef = React.useRef<HTMLInputElement>(null);
  const doSetAutoFocus = useTimeoutFn(() => {
    inputRef.current?.focus?.();
  });
  React.useEffect(() => {
    if (scannedShippingLabel && shippingScanStatus === ShippingLabelScanStatus.Complete) {
      setInputValue(valueWithSLSuffix(scannedShippingLabel.code_value));
    } else {
      setInputValue('');
    }
  }, [scannedShippingLabel, shippingScanStatus, setInputValue]);
  const isInputEnabled = React.useMemo(
    () =>
      !!orderId &&
      !isLoadingScanHistory &&
      isShippingLabelPrinted &&
      packageScanStatus === PackageLabelScanStatus.Complete &&
      shippingScanStatus !== ShippingLabelScanStatus.Complete,
    [orderId, isLoadingScanHistory, packageScanStatus, shippingScanStatus, isShippingLabelPrinted],
  );
  React.useLayoutEffect(() => {
    if (isInputEnabled) {
      return doSetAutoFocus.start(300);
    }
    return () => {};
  }, [isInputEnabled, doSetAutoFocus.start]);
  return (
    <Grid
      container
      flexDirection="column"
      alignItems="flex-start"
      gap={3}
      className={classes.subsection}
      paddingY="48px"
    >
      <Grid item data-qa-id={buildQaId('shipping-verification-shipping-label-scan-field')}>
        <Typography
          variant="body2"
          component="label"
          display="flex"
          color="grey.500"
          flexDirection="column"
        >
          Shipping Label Scan
          <TextField
            value={inputValue}
            disabled={!isInputEnabled}
            inputRef={inputRef}
            variant="standard"
            sx={{ minWidth: '300px' }}
            autoComplete="off"
            onChange={evt => {
              evt.preventDefault();
              setInputValue(evt.target.value);
            }}
            onKeyUp={evt => {
              if (evt.key === 'Enter') {
                evt.preventDefault();
                processScanInput(inputValue);
              }
            }}
            InputProps={
              isInputEnabled
                ? {
                    endAdornment: (
                      <InputAdornment position="end">
                        <PulsingScannerIcon />
                      </InputAdornment>
                    ),
                  }
                : {}
            }
          />
        </Typography>
      </Grid>

      <Grid item data-qa-id={buildQaId('shipping-verification-shipping-label-scan-status')}>
        <ShippingVerificationStatus status={shippingScanStatus} />
      </Grid>
    </Grid>
  );
}

function ShippingVerification({
  scannedPackages,
  packageScanStatus,
  shippingScanStatus,
  orderCcCount,
  orderNrCount,
  processScanInput,
  classes,
  isLoadingScanHistory,
  orderId,
  currentAncillarySupply,
}: IShippingProps): JSX.Element | null {
  const scanSectionHeight = React.useMemo(
    () => (scannedPackages.length + 1) * 32,
    [scannedPackages],
  );
  const mostRecentScanValue = React.useMemo(
    () => scannedPackages[scannedPackages.length - 1],
    [scannedPackages],
  );
  const showScannerPulse = React.useMemo(
    () =>
      !!orderId &&
      !isLoadingScanHistory &&
      packageScanStatus === PackageLabelScanStatus.Pending &&
      scannedPackages.length === 0,
    [orderId, packageScanStatus, scannedPackages, isLoadingScanHistory],
  );
  const packageScanInputRef = React.useRef<HTMLInputElement>(null);
  const prevScanValue = usePrevious(mostRecentScanValue);
  const [packageScanInputValue, setPackageScanInputValue] = React.useState<string>('');
  const [packageScanAnimate, setPackageScanAnimate] = React.useState(false);
  const clearInputTimeout = useTimeoutFn(() => {
    setPackageScanInputValue('');
    setPackageScanAnimate(false);
  });
  const [ccScannedCount, setCcScannedCount] = React.useState(0);
  const [nrScannedCount, setNrScannedCount] = React.useState(0);
  const [displayAncillarySupplyModal, setDisplayAncillarySupplyModal] = React.useState(false);

  React.useMemo(() => {
    setNrScannedCount(scannedPackages.filter(p => p.code_value.match(/-NR$/)).length);
    setCcScannedCount(scannedPackages.filter(p => p.code_value.match(/-CC$/)).length);
  }, [scannedPackages]);

  const focusOnInput = useTimeoutFn(() => {
    packageScanInputRef.current?.select?.();
  });

  React.useEffect(() => {
    const newScanHappened =
      mostRecentScanValue && prevScanValue && prevScanValue !== mostRecentScanValue;
    if (newScanHappened) {
      setPackageScanInputValue(mostRecentScanValue.code_value);
      setPackageScanAnimate(true);
      clearInputTimeout.start();
    }
  }, [prevScanValue, mostRecentScanValue]);

  React.useEffect(() => {
    const ccOk = orderCcCount ? ccScannedCount > 0 : true;
    const nrOk = orderNrCount ? orderNrCount > 0 : true;
    if (ccOk && nrOk && !displayAncillarySupplyModal) {
      setDisplayAncillarySupplyModal(true);
    }
  }, [orderCcCount, orderNrCount, ccScannedCount, nrScannedCount]);

  React.useLayoutEffect(() => {
    if (showScannerPulse) {
      return focusOnInput.start(300);
    }
    focusOnInput.clear();
    return () => focusOnInput.clear();
  }, [showScannerPulse]);

  return (
    <Grid className={classes.sectionBottomMargin}>
      <Grid className={classes.shippingVerificationPanel}>
        <Typography className={classes.shippingVerificationTitle}>Shipping Verification</Typography>
      </Grid>
      <Grid
        container
        className={classes.shippingVerificationSection}
        data-qa-id={buildQaId('shipping-verification')}
      >
        <Grid
          container
          flexDirection="column"
          alignItems="flex-start"
          gap={3}
          className={classes.subsection}
        >
          <Grid item data-qa-id={buildQaId('shipping-verification-package-scan-field')}>
            <Typography
              variant="body2"
              component="label"
              display="flex"
              color="grey.500"
              flexDirection="column"
            >
              Package Scan
              <TextField
                value={packageScanInputValue ?? ''}
                variant="standard"
                sx={{ minWidth: '300px' }}
                disabled={shippingScanStatus === ShippingLabelScanStatus.Complete}
                className={classNames({ [classes.scanInputFlash]: packageScanAnimate })}
                autoComplete="off"
                autoFocus={showScannerPulse}
                inputRef={packageScanInputRef}
                onChange={evt => {
                  setPackageScanInputValue(() => evt.target.value);
                }}
                onKeyUp={evt => {
                  if (evt.key === 'Enter') {
                    evt.preventDefault();
                    processScanInput(packageScanInputValue);
                  }
                }}
                InputProps={
                  showScannerPulse
                    ? {
                        endAdornment: (
                          <InputAdornment position="end">
                            <PulsingScannerIcon />
                          </InputAdornment>
                        ),
                      }
                    : {}
                }
              />
            </Typography>
          </Grid>

          <Grid
            item
            container
            flexDirection="column"
            sx={{
              transition: 'all 0.15s ease-out',
              height: `${scanSectionHeight}px`,
              marginRight: 'auto',
              marginBottom: '-32px',
            }}
            data-qa-id={buildQaId('shipping-verification-package-scan-list')}
          >
            {scannedPackages.map(scan => (
              <Grid
                item
                key={scan.updated_dt}
                className={classNames([
                  classes.shippingVerificationLabelValue,
                  scan.status_message === 'error' && classes.shippingVerificationLabelInvalid,
                ])}
              >
                {scan.code_value}
              </Grid>
            ))}
          </Grid>

          <Dialog open={displayAncillarySupplyModal} maxWidth="sm">
            <DialogTitle>
              This order contains Ancillary Supplies
              <Typography fontWeight={500}>
                Please confirm all Ancillary Supplies required for this order are included.
              </Typography>
            </DialogTitle>
            <DialogContent>
              <Grid container>
                {Object.entries(currentAncillarySupply).map(([key, val], idx) => {
                  if (val === true) {
                    const supplyLabel = ancillarySupplies.find(
                      supply => supply.value === key,
                    )?.label;
                    if (supplyLabel) {
                      return (
                        <Grid
                          item
                          xs={12}
                          padding={1}
                          sx={theme =>
                            idx % 2 === 0 ? { background: theme.palette.grey[100] } : {}
                          }
                        >
                          <Typography fontSize={13} fontWeight={400}>
                            {supplyLabel}
                          </Typography>
                        </Grid>
                      );
                    }
                  }
                  return <></>;
                })}
                <Grid item display="inline-flex" flexDirection="row-reverse" paddingTop={3} xs={12}>
                  <Button variant="contained" onClick={() => setDisplayAncillarySupplyModal(false)}>
                    Ok
                  </Button>
                </Grid>
              </Grid>
            </DialogContent>
          </Dialog>

          <Grid item data-qa-id={buildQaId('shipping-verification-package-scan-status')}>
            <PackingVerificationStatus status={packageScanStatus} />
          </Grid>
          {orderCcCount ? (
            <Grid item data-qa-id={buildQaId('shipping-verification-package-scan-cold-chain')}>
              <Typography
                sx={theme => ({
                  color:
                    ccScannedCount <= 0 ? theme.palette.error.main : theme.palette.success.main,
                })}
              >
                This order includes #{orderCcCount} cold chain items
              </Typography>
            </Grid>
          ) : null}
          {orderNrCount ? (
            <Grid item data-qa-id={buildQaId('shipping-verification-package-non-refrigerated')}>
              <Typography
                sx={theme => ({
                  color:
                    nrScannedCount <= 0 ? theme.palette.error.main : theme.palette.success.main,
                })}
              >
                This order includes #{orderNrCount} non-refrigerated items
              </Typography>
            </Grid>
          ) : null}
        </Grid>
      </Grid>
    </Grid>
  );
}

function BasePulsingScannerIcon({ classes }: WithStyles<typeof ShippingStyles>) {
  const myRef = React.useRef<SVGSVGElement>(null);
  const scrolledOnce = React.useRef(false);
  React.useLayoutEffect(() => {
    if (!scrolledOnce.current && myRef.current) {
      myRef.current.scrollIntoView({
        behavior: 'smooth',
        block: 'center',
      });
      scrolledOnce.current = true;
    }
  }, []);
  return <QrCodeScanner className={classes.scanIcon} ref={myRef} />;
}
export const PulsingScannerIcon = withStyles(ShippingStyles)(BasePulsingScannerIcon);

export default withStyles(ShippingStyles)(ShippingVerification);
