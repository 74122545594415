import { Theme } from '@mui/material/styles';
import createStyles from '@mui/styles/createStyles';
import { ModalUtil } from 'services/utils/modal.util';
import { expandTheme } from 'utils/theme-util';

const styles = (theme: Theme) => {
  const { palette, fonts, font } = expandTheme(theme);
  return createStyles({
    addPaymentMethodButtonText: {
      fontSize: font.smallFontSize,
      paddingTop: theme.spacing(0.25),
    },
    holidayInputFooter: {
      display: 'inline-flex',
      background: 'rgb(221, 112, 94, 0.15)',
      borderRadius: '2px',
      padding: '3px',
    },
    holidayInputFooterOuterSpan: {
      fontWeight: 500,
      fontSize: '12px',
      lineHeight: '14px',
      color: '#b5302c',
    },
    redSpan: {
      fontSize: '12px',
      lineHeight: '14px',
      color: palette.primary.lightRed,
    },
    shippingInputFooter: {
      display: 'inline-flex',
      borderRadius: '2px',
      padding: '3px',
      background: 'rgba(91, 163, 137, 0.15)',
    },
    shippingInputFooterOuterSpan: {
      fontWeight: 500,
      fontSize: '12px',
      lineHeight: '14px',
      color: '#569177',
    },
    shippingInputFooterError: {
      background: `${palette.primary.redWhite}`,
    },
    shippingInputFooterOuterSpanError: {
      color: `${palette.primary.valenciaRed}!important`,
    },
    shippingInputFooterInnerSpan: {
      fontWeight: 400,
      fontSize: '12px',
      lineHeight: '14px',
      color: '#44484C',
    },
    borderedContainer: {
      borderTop: `1px solid ${palette.primary.grey25}`,
      borderLeft: `1px solid ${palette.primary.grey25}`,
      borderRight: `1px solid ${palette.primary.grey25}`,
      paddingTop: theme.spacing(0.5),
      paddingLeft: theme.spacing(0.5),
      paddingRight: theme.spacing(0.5),
      borderRadius: theme.spacing(0.5),
    },
    therapyLevelHeader: {
      'borderLeft': '6px solid',
      'borderColor': theme.palette.primary.main,
      'paddingLeft': 15,
      'marginLeft': 6,
      '& > h3': {
        fontWeight: 600,
        fontSize: '1rem',
        letterSpacing: '-0.54px',
        display: 'flex',
        flexFlow: 'row nowrap',
        alignItems: 'center',
      },
    },
    yesNoQuestionContainer: {
      alignItems: 'center',
      borderTop: '1px solid #e1e1e1',
      paddingTop: '10px',
      paddingBottom: '10px',
    },
    yesNoQuestionText: { paddingLeft: '16px', paddingRight: '16px' },
    topPaddedContainer: {
      paddingTop: theme.spacing(3),
      paddingLeft: theme.spacing(2),
      paddingRight: theme.spacing(2),
    },
    noToAllOption: {
      paddingTop: theme.spacing(2),
      paddingLeft: theme.spacing(2),
      paddingRight: theme.spacing(2),
    },
    paddedContainer: {
      padding: theme.spacing(2),
    },
    fieldContainer: {
      marginTop: theme.spacing(1),
      marginBottom: theme.spacing(1),
      paddingRight: theme.spacing(1),
      paddingLeft: theme.spacing(1),
    },
    fieldContainerWithRedText: {
      marginTop: theme.spacing(1),
      marginBottom: theme.spacing(1),
      paddingRight: theme.spacing(1),
      paddingLeft: theme.spacing(1),
      color: palette.primary.valenciaRed,
      fontSize: '13px',
      fontWeight: 350,
      height: '56px',
      backgroundColor: '#FFF1EF',
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'space-between',
    },
    fieldContainerWithRedTextThin: {
      marginTop: theme.spacing(1),
      marginBottom: theme.spacing(1),
      paddingRight: theme.spacing(1),
      paddingLeft: theme.spacing(1),
      color: palette.primary.valenciaRed,
      fontSize: '13px',
      fontWeight: 350,
      height: '30px',
      backgroundColor: '#FFF1EF',
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'space-between',
    },
    redCircleExclamationPoint: {
      display: 'inline-block',
      width: '16px',
      fontWeight: 500,
      fontSize: '13px',
      textAlign: 'center',
      color: 'white',
      marginRight: '8px',
      backgroundColor: 'red',
      borderRadius: '50%',
    },
    yellowCircleExclamationPoint: {
      display: 'inline-block',
      width: '16px',
      fontWeight: 500,
      fontSize: '13px',
      textAlign: 'center',
      color: 'white',
      marginRight: '8px',
      borderRadius: '50%',
      backgroundColor: '#E0BB53',
    },
    fieldContainerYellowWithText: {
      marginTop: theme.spacing(1),
      marginBottom: theme.spacing(1),
      paddingRight: theme.spacing(1),
      paddingLeft: theme.spacing(1),
      borderRadius: '8px',
      color: 'black',
      fontSize: '13px',
      fontWeight: 500,
      height: '36px',
      backgroundColor: 'rgba(237, 204, 124, 0.2)',
      display: 'flex',
      alignItems: 'center',
    },
    topBorderedRow: {
      borderTop: `1px solid ${palette.primary.grey25}`,
    },
    bottomBorderedRow: {
      borderBottom: `1px solid ${palette.primary.grey25}`,
    },
    borderedRow: {
      borderTop: `1px solid ${palette.primary.grey25}`,
      borderBottom: `1px solid ${palette.primary.grey25}`,
      marginBottom: theme.spacing(3),
    },
    checkboxMediumLabel: {
      fontSize: fonts.mediumFontSize,
    },
    therapyFormWrapper: {
      padding: theme.spacing(1),
    },
    toggleLabel: {
      cursor: 'pointer',
      color: palette.primary.teal,
      fontWeight: font.weight.medium,
    },
    newButton: {
      backgroundColor: palette.primary.grey2,
      marginTop: theme.spacing(1),
      marginBottom: theme.spacing(2),
      border: 'none',
    },
    noToAllButton: {
      'backgroundColor': palette.primary.grey2,
      'marginTop': theme.spacing(1),
      'marginBottom': theme.spacing(2),
      'borderRadius': theme.spacing(20),
      'borderColor': palette.primary.grey2,
      'textTransform': 'none',
      'color': palette.primary.grey,
      'width': '100%',
      '&:hover': {
        borderColor: palette.primary.grey2,
        backgroundColor: palette.primary.grey2,
      },
    },
    copayAmountLabel: {
      margin: theme.spacing(1.5),
    },
    copayAmountBox: {
      borderRadius: theme.spacing(0.5),
      backgroundColor: palette.primary.grey25,
      width: theme.spacing(18),
      padding: theme.spacing(1),
      maxHeight: theme.spacing(3.6),
    },
    totalCopayAmountText: {
      fontWeight: 'bold',
      fontSize: fonts.largeFontSize,
    },
    shippingMethodOption: {
      textAlign: 'right',
    },
    shippingMethodOptionIcon: {
      /**
       * This number is based on trial and error mostly.
       * If the height of the images change this may need to change.
       */
      height: 15,
    },
    closeIcon: {
      cursor: 'pointer',
      float: 'left',
      marginTop: theme.spacing(0.5),
    },

    paymentMethodWarningIcon: {
      color: palette.primary.yellow,
      cursor: 'pointer',
      marginLeft: theme.spacing(1),
      marginTop: theme.spacing(4),
    },
    hidenItem: {
      visibility: 'hidden',
    },
    paymentMethodWrapper: {
      display: 'flex',
      alignItems: 'center',
    },
    infoIcon: {
      marginLeft: theme.spacing(1),
      cursor: 'pointer',
    },
    totalCopayAmountWrapper: {
      display: 'flex',
      justifyContent: 'flex-end',
      paddingRight: theme.spacing(8),
      paddingTop: theme.spacing(3),
    },
    warningIcon: {
      verticalAlign: 'middle',
      color: palette.primary.yellow,
      cursor: 'pointer',
      marginLeft: theme.spacing(0.5),
      marginBottom: '7px',
    },

    noteButtonSpacing: {
      paddingRight: theme.spacing(),
    },
    existingInterventionsText: {
      fontSize: fonts.mediumFontSize,
    },
    daySupplyConflict: {
      '& p, input, div': {
        color: palette.primary.darkRed,
        borderColor: palette.primary.darkRed,
      },
    },
    daysSupplyInfo: {
      float: 'left',
      position: 'relative',
      top: theme.spacing(-8),
      paddingLeft: theme.spacing(12),
      cursor: 'pointer',
      color: palette.primary.rollingStone,
      borderColor: palette.primary.darkRed,
    },
    workAsGroupContainer: {
      padding: theme.spacing(1),
    },
    workAsGroupText: {
      fontSize: fonts.mediumFontSize,
    },
    prnIcon: {
      width: '30px',
      paddingLeft: theme.spacing(0.8),
      marginBottom: theme.spacing(-0.5),
    },
    otfIcon: {
      borderRadius: '3px',
      padding: '3px 4px',
      marginLeft: '8px',
      fontWeight: font.weight.thick,
      fontSize: '0.6rem',
      lineHeight: 'normal',
      color: palette.primary.white,
      backgroundColor: palette.primary.deepBlue,
    },
    modal: {
      ...ModalUtil.BasicModalStyling(theme, 80, 3),
    },
    modalText: {
      marginBottom: theme.spacing(2),
    },
    modalContent: {
      display: 'flex',
      gap: theme.spacing(1),
      justifyContent: 'flex-end',
    },
    secureLinkContainer: {
      display: 'flex',
      justifyContent: 'space-between',
      height: '75px',
      alignItems: 'center',
      paddingLeft: '8px',
    },
    secureLinkContainerThin: {
      display: 'flex',
      justifyContent: 'space-between',
      height: '20px',
      alignItems: 'center',
      paddingLeft: '8px',
    },
    secureLinkButton: {
      display: 'flex',
      justifyContent: 'end',
      paddingRight: '8px',
    },
  });
};

export { styles };
