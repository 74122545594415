import React from 'react';
import arJson from 'config/task-types/ar.json';
import {
  SCHEDULE_OUTREACH_STATUS_COMMUNICATION_PHONE_ID,
  SCHEDULE_OUTREACH_STATUS_COMMUNICATION_INPERSON_ID,
  SCHEDULE_OUTREACH_STATUS_COMPLETED_REASON_PHONE_ID,
  SCHEDULE_OUTREACH_STATUS_COMPLETED_REASON_INPERSON_ID,
  EDIT_SCHEDULE_OUTREACH_FORM,
  AR,
} from 'constants/index';
import { useDispatch, useSelector } from 'react-redux';
import { change } from 'redux-form';
import { TaskStatus } from 'interfaces/enums/TaskStatus';

import { unableToSeeReasons } from 'constants/lists';
// eslint-disable-next-line max-len
import ScheduleOutreachTherapies from 'containers/patient/checklist/schedule-outreach/schedule-outreach-therapies';
import { areAllDrugsValidated } from 'services/utils/task-service';
import { staticProviders, getCommonProviders } from './common-provider';

const ScheduleOutreachTherapiesWrapper = React.memo(
  ({ providers }) => {
    const dispatch = useDispatch();
    const { task, json, statuses } = providers;
    const scheduleOutreachList = useSelector(state => state.scheduleOutreachList);
    const taskStatuses = useSelector(state => state.taskStatuses);
    const orTask = scheduleOutreachList.find(it => it.id === task.id) || task;
    return (
      <ScheduleOutreachTherapies
        scheduleOutreach={orTask}
        setTaskStatus={status => {
          let allStatuses =
            taskStatuses && taskStatuses.statuses && taskStatuses.statuses.ar
              ? taskStatuses.statuses.ar
              : null;
          if (!allStatuses && json && json.id === AR) {
            allStatuses = statuses ? statuses.map(s => ({ ...s, status: s.name })) : null;
          }
          if (!allStatuses) {
            allStatuses = [];
          }
          const statusId = allStatuses.find(it => it.status === status)
            ? statuses.find(it => it.status === status).id
            : null;
          if (statusId) {
            dispatch(change(`${EDIT_SCHEDULE_OUTREACH_FORM}_${task.id}`, 'status_id', statusId));
          }
        }}
      />
    );
  },
  (prevProps, nextProps) => prevProps.providers.task !== nextProps.providers.task,
);

export default (task, state) => ({
  ...staticProviders,
  ...getCommonProviders(task, state, arJson),
  json: arJson,
  initialValues: {
    completed_date: state.currentDateTime,
  },
  scheduleOutreachList: state.scheduleOutreachList,
  communicationTypes: [
    {
      id: Number(SCHEDULE_OUTREACH_STATUS_COMMUNICATION_PHONE_ID),
      name: 'Phone',
    },
    {
      id: Number(SCHEDULE_OUTREACH_STATUS_COMMUNICATION_INPERSON_ID),
      name: 'In Person',
    },
  ],
  unableToSeeReasons: unableToSeeReasons.map(it => ({
    id: it.value,
    name: it.label,
  })),
  manualArReasonValues: state?.lookups?.lookupScheduleOutreachReasons?.map(reason => ({
    id: reason.id,
    name: reason.name,
  })),
  convertResultValues: {
    status_id: (values, value) => {
      if (
        value === SCHEDULE_OUTREACH_STATUS_COMPLETED_REASON_INPERSON_ID ||
        value === SCHEDULE_OUTREACH_STATUS_COMPLETED_REASON_PHONE_ID
      ) {
        if (
          values.communication_type_id ===
          Number(SCHEDULE_OUTREACH_STATUS_COMMUNICATION_INPERSON_ID)
        ) {
          return SCHEDULE_OUTREACH_STATUS_COMPLETED_REASON_INPERSON_ID;
        }
        return SCHEDULE_OUTREACH_STATUS_COMPLETED_REASON_PHONE_ID;
      }
      return value;
    },
  },
  customElements: [
    {
      id: 'ar-data',
      content: ScheduleOutreachTherapiesWrapper,
    },
  ],
  customStatusDisabled: statusName => {
    if (!areAllDrugsValidated(task.drugs)) {
      // If not all drugs are validated, the following are the only enabled status keys
      const enabledStatusKeysWhenDrugsNotValidated = [
        TaskStatus.Validate,
        TaskStatus.Scheduled,
        TaskStatus.UnableToMeetWithPatient,
        TaskStatus.WillNotMeetWithPatient,
        TaskStatus.NoValidTherapies,
        TaskStatus.PatientUnreachable,
        TaskStatus.OfferLaterDate,
        TaskStatus.SpokewithPatient,
      ];
      return !enabledStatusKeysWhenDrugsNotValidated.includes(statusName);
    }
    return false;
  },
});
