import { InputAdornment, TextField, Typography } from '@mui/material';
import makeStyles from '@mui/styles/makeStyles';
import lodash from 'lodash';
import React, { useState, useCallback, useEffect } from 'react';
import { NumericFormat } from 'react-number-format';
import { styles } from './field-styles';
import Validation from '../validation/validation';

const INPUT_DEBOUNCE_MS = 500;

const useStyles = makeStyles(styles);

export default function DebouncedNumericField({
  input,
  onChange,
  value,
  decimal,
  label,
  disabled,
  allowNegative = false,
  meta: { touched, error, warning },
  qaId,
  suffix,
  defaultToZero,
  missing_value_id,
  ...custom
}) {
  const classes = useStyles();
  const shouldDefaultToZero = !input.value && defaultToZero;
  const defaultValue = input.value ? Number(input.value) : input.value;
  const [val, setVal] = useState(shouldDefaultToZero ? 0 : defaultValue);
  const { width } = custom;
  const containerWidth = width || '75%';
  useEffect(() => {
    setVal(input.value);
  }, [input.value]);
  const debouncedOnChange = useCallback(
    lodash.debounce((e, v) => {
      if (e && e.target) {
        input.onChange(e.target.value);
        if (onChange) {
          onChange(v);
        }
      }
    }, INPUT_DEBOUNCE_MS),
    [],
  );
  const missing_value_prop =
    missing_value_id && typeof missing_value_id === 'object'
      ? { [Object.keys(missing_value_id)[0]]: Object.values(missing_value_id)[0] }
      : {};
  return (
    <div
      style={{
        display: 'inline-flex',
        flexDirection: 'column',
        width: containerWidth,
      }}
    >
      {label && <Typography className={classes.fieldLabel}>{label}</Typography>}
      <NumericFormat
        {...custom}
        allowNegative={allowNegative}
        customInput={TextField}
        disabled={disabled}
        decimalSeparator={decimal ? '.' : ''}
        value={val ? Number(val) : val}
        variant="standard"
        onChange={e => {
          setVal(e.target.value);
          e.persist();
          debouncedOnChange(e);
        }}
        inputProps={{
          ...custom.inputProps,
          'data-qa-id': qaId,
          ...missing_value_prop,
        }}
        // eslint-disable-next-line react/jsx-no-duplicate-props
        InputProps={{
          ...custom.InputProps,
          endAdornment: suffix ? (
            <InputAdornment position="end">{suffix}</InputAdornment>
          ) : undefined,
        }}
      />
      <Validation touched={touched} error={error} warning={warning} />
    </div>
  );
}
